@import '../../styles/customMediaQueries.css';

.root {}

.pageRoot {
  padding-bottom: 90px;

  @media (--viewportLarge) {
    padding-bottom: 0;
  }
}

.listingWrapper {
  display: flex;
  padding-bottom: 60px;

  @media (max-width: 1023px) {
    flex-direction: column;
  }
}

.loadingText {
  margin: 24px;
}

.errorText {
  color: var(--colorFail);
  margin: 24px;
}

.sectionHero {
  position: relative;
  margin: 0 0 36px 0;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.heroHeight {
  @media (--viewportMedium) {
    height: calc(0.41 * 100vw);
    max-height: 50vh;
    overflow-y: hidden;
  }
}

.imageWrapperForSectionHero {
  composes: heroHeight;
  background-color: var(--colorGrey100);
  /* Loading BG color */

  /* Image carousel can be opened from the image, therefore we should show a pointer */
  cursor: pointer;
}

.actionBar {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  color: var(--colorGrey100);
  background-color: var(--colorGrey700);
  z-index: 1;
  /* bring on top of mobile image */

  /* Action bar prevents the image click events going to the parent and
   should not show a pointer */
  cursor: initial;

  border-radius: var(--borderRadius);

  @media (--viewportMedium) {
    flex-direction: row;
  }

  @media (max-width: 767px) {
    background-color: transparent;
    color: #000;
  }
}

.actionBarForProductLayout {
  margin: 24px 0;
  width: calc(100% - 48px);

  @media (--viewportMedium) {
    margin: 24px 0 24px 0;
    width: 100%;
  }

  @media (--viewportLarge) {
    margin: 0 0 24px 0;
  }
}

.actionBarContainerForHeroLayout {
  position: absolute;
  top: 13px;
  left: 13px;
  width: calc(100% - 26px);
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.actionBarForHeroLayout {
  /* Action bar prevents the image click events going to the parent and
   should not show a pointer */
  cursor: initial;
}

.ownListingText {
  composes: h5 from global;
  margin: 14px 0;

  @media (--viewportMedium) {
    margin: 24px 12px 24px 24px;
  }
}

.ownListingTextPendingApproval {
  color: var(--colorAttention);
}

.closedListingText {
  composes: h4 from global;
  margin: 14px 12px 11px 24px;
  text-align: center;
  width: 100%;

  @media (--viewportMedium) {
    margin: 25px 12px 22px 24px;
  }
}

.addPayoutDetails,
.editListingLink {
  composes: h5 from global;
  flex-shrink: 0;
  margin: 14px 24px 11px 24px;
  color: var(--colorGrey100);

  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--colorWhite);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin: 24px 24px 24px 12px;
  }
}

.addPayoutDetails,
.editListingLink {
  @media (max-width: 767px) {
    background: #000;
    width: fit-content;
    padding: 6px 10px;
    border-radius: 6px;
    margin: 0;
  }
}

.missingPayoutDetailsText {
  color: var(--colorAttention);
}

.payoutDetailsWarning {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.editIcon {
  margin: -6px 7px 0 0;
}

.rootForImage {
  composes: heroHeight;
  width: 100%;
  transition: var(--transitionStyleButton);

  @media (--viewportMedium) {
    object-fit: cover;
    border-radius: var(--borderRadius);

    &:hover {
      transform: scale(1.005);
      box-shadow: var(--boxShadowListingCard);
    }
  }
}

.viewPhotos {
  composes: marketplaceTinyFontStyles from global;
  font-weight: var(--fontWeightMedium);

  /* Position and dimensions */
  position: absolute;
  bottom: 19px;
  right: 24px;
  margin: 0;
  padding: 8px 13px 6px 13px;

  /* Colors */
  background-color: var(--colorWhite);

  /* Borders */
  border: none;
  border-radius: var(--borderRadius);

  cursor: pointer;
  transition: var(--transitionStyleButton);

  &:hover {
    background-color: var(--marketplaceColor);
    color: var(--colorWhite);
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.carouselModalScrollLayer {
  width: 100vw;
  height: 100vh;
  background-color: rgba(1, 1, 1, 0.92);
}

.carouselModalContainer {
  width: 100%;
  height: 100%;
}

.contentWrapperForHeroLayout {
  @media (--viewportMedium) {
    width: 100%;
    max-width: var(--contentMaxWidth);
    margin: 0 auto;
    padding: 0 24px;
    display: flex;
    position: relative;
  }

  @media (--viewportLarge) {
    max-width: calc(var(--contentMaxWidth) + 72px);
    padding: 0 36px;
    margin: 0 auto 117px;
  }

  @media (--viewportXLarge) {
    max-width: var(--contentMaxWidth);
    padding: 0;
  }
}

.mainColumnForProductLayout {
  @media (--viewportMedium) {}

  @media (--viewportLarge) {
    /* contentContainer's full width - (orderColumn + paddings + margin) */
    max-width: calc(100% - 480px);
    flex-basis: calc(100% - 480px);
    /* flex-shrink: 0;
    flex-grow: 1; */
  }
}

.orderColumnForProductLayout {
  @media (--viewportMedium) {}

  @media (--viewportLarge) {
    display: block;
    margin-left: 20px;
    flex-basis: 50%;
    flex-shrink: 0;
    /* margin-top: 55px; */
  }
}

.aboutPhotographer {
  @media (min-width: 1023px) {
    margin-left: 20px;
  }
}

.contentWrapperForProductLayout {
  margin: 0 0 0px 0;
  padding: 30px 24px 0 24px;

  @media (--viewportMedium) {
    width: 100%;
    max-width: var(--contentMaxWidth);

    margin: 0 auto 0px auto;
  }

  @media (--viewportLarge) {
    max-width: calc(1884px + 72px);
    width: 96%;
    padding: 34px 0px 0 0px;
    margin: 0 auto 0px auto;
  }

  @media (min-width: 1300px) {
    padding: 34px 36px 0 36px;
  }
}

.mainColumnForHeroLayout {
  flex-basis: 100%;
  margin-bottom: 23px;

  @media (--viewportMedium) {
    margin-top: 50px;
    margin-bottom: 51px;
    flex-shrink: 0;
  }

  @media (--viewportLarge) {
    margin-top: 80px;
    margin-bottom: 0px;
    /* contentContainer's full width - (orderColumn + two paddings + margin + border) */
    max-width: calc(100% - 433px);
    flex-basis: calc(100% - 433px);
    flex-shrink: 0;
    flex-grow: 1;
  }
}

.orderColumnForHeroLayout {
  @media (--viewportMedium) {}

  @media (--viewportLarge) {
    display: block;
    margin-top: 80px;
    margin-left: 60px;
    border-left: 1px solid var(--colorGrey100);
    padding-left: 60px;
    flex-basis: 312px;
    flex-shrink: 0;
  }
}

.orderPanel {

  /* Note: panel height might be too much on small viewport - hence min-height */
  @media (--viewportLarge) and (min-height: 950px) {
    position: sticky;
    top: 108px;
  }
}

.productOrderPanel {
  @media (--viewportLarge) {
    display: block;
    margin-top: 120px;
  }

  /* Note: panel height might be too much on small viewport - hence min-height */
  @media (--viewportLarge) and (min-height: 950px) {
    /* position: absolute;
    bottom: 0; */
  }

  /* &>div{
    @media (max-width: 767px) {
      display: block;
    }
  } */
  & form {
    @media (max-width: 767px) {
      padding: 24px;
    }
  }
}

.imageModal {
  &>div {
    background-color: #fff !important;

    &>div {
      flex-basis: 1280px !important;
      padding: 0 !important;
      border-radius: 20px !important;
      margin-left: 120px;
      margin-right: 120px;

      @media (max-width: 1023px) {
        margin-left: 16px;
        margin-right: 16px;
        margin-top: 80px;
        box-shadow: unset !important;
      }

      &>button {
        display: none;
      }

      & img {
        /* border-radius: 20px 20px 0 0; */
        width: 100%;
        height: 100%;
      }

      &>div {
        @media (max-width: 1023px) {
          box-shadow: 0px 4px 60px 0px #0000000F;
          border-radius: 20px;
        }
      }
    }
  }

  & :global(.react-transform-wrapper) {
    /* border-radius: 20px 20px 0 0 !important; */
    background-color: #000;
    width: 100%;

    &>div {
      width: 100%;
      justify-content: center;
    }
  }
}

.expandModalWrapper {
  &>div {
    &>div {
      flex-basis: 1884px !important;
      margin-left: 30px;
      margin-right: 30px;

      @media (max-width: 1023px) {
        margin-left: 16px;
        margin-right: 16px;
      }
    }
  }
}

.expandModal {
  position: absolute;
  top: 40px;
  right: 40px;

  & svg {
    fill: transparent;
  }

  @media (max-width: 1023px) {
    display: none;
  }
}

.zoomTools {
  position: absolute;
  z-index: 1;
  left: 40px;
  top: 40px;

  @media (max-width: 1023px) {
    left: 16px;
    right: 16px;
    top: 16px;
  }

  & button {
    width: 100px;
    height: 53px;
    font-size: 38px;
    background: #d2d2d2;
    border: 1px solid gray;
    color: #fff;
    cursor: pointer;
    line-height: 0;

    @media (max-width: 1023px) {
      width: 40px;
      height: 30px;
      padding: 0;
    }

    &:hover {
      background: #a6a6a6;
    }

    & svg {
      @media (max-width: 1023px) {
        height: 18px;
        width: 18px;
      }
    }
  }

  & .zoomIn {
    border-radius: 20px 0 0 20px;
  }

  & .zoomOut {
    border-radius: 0;
  }

  & .zoomCancel {
    border-radius: 0 20px 20px 0;
  }
}

.modalBottomContent {
  display: flex;
  gap: 18px;
  justify-content: flex-end;
  border-top: 1px solid #c3c3c3;
  padding: 14px 30px;

  & button {
    border: none;
    background-color: #FC690C;
    font-family: Poppins;
    font-size: 13.24px;
    font-weight: 400;
    line-height: 19.86px;
    text-align: center;
    border-radius: 10px;
    height: 40px;
    gap: 18px;
    width: fit-content;
    padding: 0px 16px;
    color: #FFFFFF;
    min-height: 40px;
  }

  & .cancelButton {
    font-weight: 400;
    color: #000;
    background-color: #F4F4F4;
  }
}

.orderPanelTitle {
  /* Font */
  font-family: Poppins;
  font-size: 32px;
  font-weight: 600;
  line-height: 48px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #000;
  text-transform: capitalize;
  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
}

.sectionAvatar {
  /* Position (over the listing image)*/
  margin-left: 24px;
  margin-top: -31px;

  /* Rendering context to the same lavel as listing image */
  position: relative;

  /* Flexbox would give too much width by default. */
  width: 60px;

  @media (--viewportMedium) {
    position: absolute;
    top: -49px;
    margin-left: 0;
    margin-top: 0;
  }
}

.avatarMobile {
  display: flex;

  @media (--viewportMedium) {
    display: none;
    /* Hide the medium avatar from the bigger screens */
  }
}

.avatarDesktop {
  display: none;
  /* Hide the large avatar from the smaller screens */

  @media (--viewportMedium) {
    display: flex;
  }
}

.desktopPriceContainer {
  display: none;

  @media (--viewportLarge) {
    /* Layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    margin: 0 48px 0 0;
    padding: 0;
  }
}

.desktopPriceValue {
  /* Font */
  composes: marketplaceModalTitleStyles from global;
  color: var(--marketplaceColor);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 1px;
  }
}

.desktopPerUnit {
  /* Font */
  composes: h5 from global;
  color: var(--colorGrey700);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.heading {
  margin-bottom: 33px;
  padding: 0 24px;

  @media (--viewportMedium) {
    margin-bottom: 0;
    padding: 0;
  }
}

.title {
  /* Font */
  composes: marketplaceModalTitleStyles from global;
  color: var(--colorGrey700);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 2px;
  }
}

.author {
  width: 100%;
  composes: h5 from global;

  margin-top: 7px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    color: var(--colorGrey700);
  }
}

.authorNameLink {
  composes: textSmall from global;
  color: var(--colorGrey700);
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0.02em;
  text-align: left;
  text-transform: capitalize;
}

.contactWrapper {
  display: none;

  @media (--viewportMedium) {
    display: inline;
  }
}

.separator {
  margin: 0 6px;
}

.contactLink {
  /* Position and dimensions */
  display: inline;
  padding: 0;

  /* Borders */
  border: none;

  /* Colors */
  color: var(--marketplaceColor);

  /* Text size should be inherited */
  text-decoration: none;

  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
  margin: 0;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media (--viewportMedium) {
    line-height: 16px;
  }
}

.sectionText {
  padding: 0 24px;
  margin-bottom: 36px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 40px;
  }
}

.sectionHeading,
.sectionHeadingWithExtraMargin {
  /* Font for sections titles */
  composes: p from global;
  padding: 0;
  font-weight: var(--fontWeightBold);

  margin-top: 0;
  margin-bottom: 12px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 8px;
  }
}

.sectionHeadingWithExtraMargin {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #000;

  @media (--viewportMedium) {
    margin-bottom: 16px;
  }
}

.text {
  font-weight: var(--fontWeightRegular);
  padding: 1px 0 5px 0;
  margin: 0;

  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;

  @media (--viewportMedium) {
    padding: 3px 0 5px 0;
    margin: 0;
  }
}

.ingress {
  composes: p from global;
  margin: 0;

  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.sectionDetails,
.sectionMultiEnum {
  padding: 0 24px;
  margin-bottom: 32px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 40px;
  }
}

.sectionMap {
  padding: 0 24px;
  margin-bottom: 35px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 0;
  }
}

.sectionReviews,
.sectionAuthor {
  margin-top: 20px;

  @media (--viewportMedium) {
    padding: 0;
    margin-top: 20px;
  }

  @media (--viewportLarge) {
    padding: 0px 0;
  }
}

.details {
  padding-bottom: 1px;
  margin: 0;
}

.detailsRow {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  padding: 1px 0 4px 0;
  line-height: 36px;
  list-style: none;
  font-weight: var(--fontWeightRegular);

  &:not(:last-child) {
    border-bottom: 1px solid var(--colorGrey100);
  }

  @media (--viewportMedium) {
    padding: 5px 0 2px 0;
    line-height: 32px;
  }
}

.detailLabel {
  font-weight: var(--fontWeightSemiBold);
  letter-spacing: -0.09px;
}

.inquiryModalContent {
  flex-grow: 1;
  display: flex;
}

.inquiryForm {
  flex-grow: 1;
  margin: 70px 0 100px 0;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    min-height: 400px;
    margin: 0;
  }
}

.inquirySubmitButtonWrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 24px;
  background-color: var(--colorWhite);

  @media (--viewportMedium) {
    position: static;
    padding: 0;
    margin-top: auto;
  }
}

.map {
  /* Dimensions: Map takes all available space from viewport (excludes action button and section title) */
  height: calc(100vh - 193px);
  width: 100%;

  /* Static map: dimensions are 640px */
  max-width: 640px;
  max-height: 640px;
  background-color: #eee;
  padding: 0;
  border: 0;
  cursor: pointer;

  @media (--viewportMedium) {
    height: 75vh;
  }

  @media (--viewportLarge) {
    height: 417px;
    margin-bottom: 7px;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.productGallery {
  margin: 0 0 36px 0;

  @media (--viewportMedium) {
    margin: 24px 0 48px 0;
  }

  @media (--viewportLarge) {
    margin: 0 0 40px 0;
    padding-bottom: 4px;
  }
}

.mobileHeading {
  margin: 0 24px 24px 24px;

  @media (--viewportMedium) {
    margin: 0 0 24px 0;
  }

  display: none;

}

.productDesktopHeading {
  display: none;

  @media (--viewportLarge) {
    display: block;
  }
}

.productSideContent {}

.productBookingPanel {
  @media (--viewportLarge) {
    display: block;
    margin-top: 24px;

    /* The calendar needs 312px */
    flex-basis: 312px;
    flex-shrink: 0;
  }
}

.imagesWrapper {
  height: 1088px;
  overflow-y: auto;

  @media (min-width: 1024px) {
    background: #FAFAFA;
    padding: 20px;
  }

  @media (min-width: 1500px) {
    padding: 60px;
  }

  @media (max-width: 767px) {
    height: 400px;
  }
}

.mobilePackage {
  @media (min-width: 768px) {
    display: none;
  }
}

.selectedPicture {
  border: 6px solid #FC690C;
  border-radius: 20px;
}

.imagesContainer {
  display: grid;
  /* grid-template-columns: repeat(4, 1fr); */
  grid-gap: 30px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  border-radius: 12px;
  overflow: hidden;

  @media (max-width: 1500px) {
    grid-gap: 16px;
  }

  grid-template-areas: 'image-1 image-1 image-1 image-1'
  'image-3 image-3 image-2 image-2'
  'image-4 image-4  image-4 image-4 '
  'image-6 image-6 image-5 image-5'
  'image-7 image-7 image-7 image-7'
  'image-9 image-9 image-8 image-8'
  'image-10 image-10 image-10 image-10'
  'image-12 image-12 image-11 image-11'
  'image-13 image-13 image-13 image-13'
  'image-15 image-15 image-14 image-14'
  'image-16 image-16  image-16 image-16'
  'image-18 image-18 image-17 image-17'
  'image-19 image-19 image-19 image-19'
  'image-20 image-20  image-20 image-20'
  ;

  @media (max-width: 767px) {
    grid-template-columns: 100%;
    grid-template-rows: unset;
    grid-gap: 16px;
    grid-template-areas:
      'image-1'
      'image-2'
      'image-3'
      'image-4'
      'image-5'
      'image-6'
      'image-7'
      'image-8'
      'image-9'
      'image-10'
      'image-11'
      'image-12'
      'image-13'
      'image-14'
      'image-15'
      'image-16'
      'image-17'
      'image-18'
      'image-19'
      'image-20';
  }

  &>div {
    & .listingImage {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 20px;

      /* max-height: 573px; */
      @media (max-width: 1500px) {
        /* max-height: 400px; */
      }
    }

    & .logoImage {
      width: 40%;
      height: auto;
      background: #00000017;
      border-radius: 100px;
    }

    @media (max-width: 767px) {
      /* height: 320px; */
    }

    /* min-height: 320px; */
    border-radius: 20px;

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &>div {
      height: 100%;
    }

    &:nth-child(1) {
      grid-area: image-1;
    }

    &:nth-child(2) {
      grid-area: image-2;
    }

    &:nth-child(3) {
      grid-area: image-3;
    }

    &:nth-child(4) {
      grid-area: image-4;
    }

    &:nth-child(5) {
      grid-area: image-5;
    }

    &:nth-child(6) {
      grid-area: image-6;
    }

    &:nth-child(7) {
      grid-area: image-7;
    }

    &:nth-child(8) {
      grid-area: image-8;
    }

    &:nth-child(9) {
      grid-area: image-9;
    }

    &:nth-child(10) {
      grid-area: image-10;
    }

    &:nth-child(11) {
      grid-area: image-11;
    }

    &:nth-child(12) {
      grid-area: image-12;
    }

    &:nth-child(13) {
      grid-area: image-13;
    }

    &:nth-child(14) {
      grid-area: image-14;
    }

    &:nth-child(15) {
      grid-area: image-15;
    }

    &:nth-child(16) {
      grid-area: image-16;
    }

    &:nth-child(17) {
      grid-area: image-17;
    }

    &:nth-child(18) {
      grid-area: image-18;
    }

    &:nth-child(19) {
      grid-area: image-19;
    }

    &:nth-child(20) {
      grid-area: image-20;
    }
  }

  & .section {
    display: flex;
    flex-direction: column;
    position: relative;
    border-radius: 20px;
    z-index: 1;
    justify-content: center;
    border: 1px solid #8080804d;
    overflow: hidden;

    & .waterMark {
      position: absolute;
      z-index: 1;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      /* background: #00000021; */
      border-radius: 12px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      justify-items: center;
      transform: rotate(-45deg);

      & img {
        min-width: 60px;

        &:nth-child(3) {
          margin-left: 60%;
        }

        &:nth-child(4) {
          margin-left: 60%;
        }
      }

      & .waterMarkText {
        color: #fff;
        font-size: 28px;

        @media (max-width: 767px) {
          font-size: 20px;
        }
      }
    }

    & .countNumber {
      background: rgba(0, 0, 0, 0.462);
      border-radius: 0 20px 0 20px;
      height: 80px;
      width: 80px;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 0 0 auto;

      @media (max-width: 1500px) {
        width: 60px;
        height: 60px;
      }
    }

    & .dotCircle {
      background: #FC690C;
      border-radius: 0;
      border-radius: 0 0 0 20px;
      width: 80px;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: center;

      @media (max-width: 1500px) {
        width: 60px;
        height: 60px;
      }

      & svg {
        width: 32px;
        height: 25px;
        stroke: #fff;

        @media (max-width: 1023px) {
          width: 24px;
        }

        & path {
          fill: #fff;
        }
      }
    }

    & label {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      padding: 0;
      cursor: pointer;
      z-index: 10;
      margin: 0;
    }

    & input {
      position: absolute;
      opacity: 0;
      height: 0;
      width: 0;
    }
  }
}

.selectedImage {
  border: 3px solid #00adb5;
}

.bannerTitle {
  background: #FC690C;
  height: 62px;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #fff;
  padding: 16px 60px;

  @media (max-width: 767px) {
    font-size: 16px;
    padding: 16px 24px;
  }
}

.imageTitle {
  font-family: Poppins;
  font-size: 40px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #FC690C;
  margin: 40px 0 56px;
  padding: 0;

  @media (max-width: 767px) {
    font-size: 32px;
  }
}

.bottomContents {
  margin: 0 0 56px 0;
  padding: 0px 24px 0 24px;

  @media (--viewportMedium) {
    width: 100%;
    max-width: var(--contentMaxWidth);

    margin: 0 auto 56px auto;
  }

  @media (--viewportLarge) {
    max-width: 644px;
    min-width: 480px;
    width: 100%;
    padding: 0;
    margin: 0 auto 117px auto;
  }

  @media (min-width: 1300px) {
    min-width: 540px;
  }
}

/* width */
::-webkit-scrollbar {
  width: 12px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 12px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.extraList {
  margin-bottom: 16px;
  margin-bottom: 16px;
  border: 2px solid #F5F5F5;
  border-radius: 14px;
  padding: 6px 12px;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media(max-width: 600px) {
    width: 100%;
  }

  & label {
    padding: 0;
  }
}